<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="210px" style="background: #fff;border-right: 1px solid #DCDFE6;">
        <div class="menu-title">
          <span>产业分类</span> 
        </div>
        <el-scrollbar :style="{'height':(windowHeight-81-61)+'px'}">
          <ul>
            <li class="menu-item" :class="{'cur':selIndustryClass==0}">
              <a  @click.prevent="()=>{selIndustryClass=0;selIndustryClass_name='全部';selSubIndustryClass=0; selSubIndustryClass_name='';searchStr='';getIndustryChainListFun('');}">
                  <!-- <i class="el-icon-folder fontsize14"></i> -->
                  <span>全部</span>
              </a>
            </li>
            <li v-for="item in industryClassList" :key="item.value" class="menu-item" :class="{'cur':selIndustryClass==item.value}">
              <a v-if="item.chainNum>0" @click.prevent="()=>{selIndustryClass=item.value;selIndustryClass_name=item.label;selSubIndustryClass=0;selSubIndustryClass_name='';searchStr=''; getIndustryChainListFun(item.value);}">
                  <!-- <i class="el-icon-folder fontsize14"></i> -->
                  <span>{{item.label}}</span>
                  <!-- <i :class="{'el-icon-arrow-right':selIndustryClass!=item.value,'el-icon-arrow-down':selIndustryClass==item.value}" class="icon-arrow"></i> -->
              </a>
              <ul v-if='false'>
              <!-- <ul v-show='selIndustryClass==item.value'> -->
                <li v-for="subItem in item.children" :key="subItem.value" class="sub-item" :class="{'cur':selSubIndustryClass==subItem.value}">
                  <a @click.prevent="()=>{selSubIndustryClass=subItem.value;selSubIndustryClass_name=subItem.label;searchStr='';getIndustryChainListFun(subItem.value);}">
                      <!-- <i class="el-icon-folder fontsize14"></i> -->
                      <span>{{subItem.label}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </el-scrollbar>
      </el-aside>
      <el-main>
            <el-breadcrumb separator=">">
              <el-breadcrumb-item >
                <a v-if='selSubIndustryClass!=0' @click.prevent="()=>{searchStr='';getIndustryChainListFun(selIndustryClass);}">
                 {{selIndustryClass_name}}
                </a>
                <span v-else>{{selIndustryClass_name}}</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if='selSubIndustryClass!=0'>{{selSubIndustryClass_name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="fr saas_header_search">
              <el-input v-model="searchStr"  placeholder="搜索" @keydown.enter="getIndustryChainListFun('')">
                <template #append>
                    <el-button icon="el-icon-search" @click="getIndustryChainListFun('')" ></el-button>
                    <!-- <el-link :underline="false" icon="el-icon-search" target='_blank' :href='"/#/EconoSearch?key="+searchStr'> </el-link> -->
                </template>
              </el-input>
            </div>
            <el-row :gutter="40">
              <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" v-for="chain in industryChainList" :key="chain.id">
                <div class="grid-content" @click="selIndustryChain(chain.id)">
                  <h5>{{chain.name}}</h5>
                  <el-image :src="chain.logoUrl" fit="fill">
                    <template #error>
                      <div class="image-slot">
                        <i class="iconfont ec-icon-image"></i>
                      </div>
                    </template>
                  </el-image>
                  <div class="grid-title" >
                    <span class="txt">
                      <span class="num">{{chain.serviceNum}}</span>个节点，<span class="num">{{chain.epNum}}</span>家企业
                    </span>
                  </div>
                  
                </div>
              </el-col>
            </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import {getIndustryClassList,getChainNumOfIndustryClass,getIndustryChainList} from '@/http/industryChain.js'
import {addUserLog} from '@/http/basicsApi.js'
import { ref } from '@vue/reactivity'
import axios from 'axios'
import { useRouter } from 'vue-router'
export default {
  name:'IndustryChainList',
  setup() {
    const router=useRouter();//路由
    let industryClassList=ref([]);//产业分类列表
    let selIndustryClass=ref(0);//选中的产业父类
    let selIndustryClass_name=ref('全部')
    let selSubIndustryClass=ref(0);//选中的产业子类
    let selSubIndustryClass_name=ref('');
    let searchStr=ref('');//搜索
    const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
    //获取产业分类列表
    const getIndustryClassListFun=async()=>{
      let result=await getIndustryClassList();
       console.log(result);
      if(result.code==20000){
        industryClassList.value=result.data;
        console.log(industryClassList.value);
      }
      axios.all([getIndustryClassList(), getChainNumOfIndustryClass('')])
      .then(axios.spread(function (resp1, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
          // 两个请求现在都执行完成
          console.log(resp1);
          if(resp1 && resp1.code==20000){
            industryClassList.value=resp1.data;
            //两级产业分类
            industryClassList.value.forEach(t=>{
              let chainNum=0;
              t.children.forEach(c=>{
                  c.chainNum=0;
                if(resp2.data){
                  let chainNumObj=resp2.data.find(m=>m.classId==c.value);
                  if(chainNumObj){
                    chainNum=chainNum+chainNumObj.chainNum;
                    c.chainNum=chainNumObj.chainNum;
                  }
                }
              })
              t.chainNum=chainNum;
            })
          }
          else{
              industryClassList.value=[];
          }
      }));




    }
    //获取产业链
    let industryChainList=ref([]);//产业链列表
    const getIndustryChainListFun=async(classId)=>{
      let params={
        classId:classId,
        search:searchStr.value,
        isShow:1,
        pageIndex:1,
        pageSize:1,
      };
      let result=await getIndustryChainList(params);
      console.log(result);
      if(result.code==20000){
        
        industryChainList.value=result.data.dataList;
      }
      //记录用户操作日志
      addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'查询',JSON.stringify(params));
    }


/*
    const getIndustryClassListFun_=async()=>{
       let params={
        pageIndex:1,
        pageSize:1,
      };
      axios.all([getIndustryClassList(), getIndustryChainList(params)])
      .then(axios.spread(function (resp1, resp2) {	// spread是将各请求结果拆分返回，如果不使用改方法，则最后的相应结果为一个数组[resp1, resp2]
          // 两个请求现在都执行完成
          console.log(resp1);
          if(resp1 && resp1.code==20000 && resp2 && resp2.code==20000){
              resp1.data.forEach(t=>{
                let childClassAry=t.children.map(m=>m.value);//两级产业分类
                // t.industryChainList=resp2.data.dataList.filter(tm=>{
                //   return childClassAry.indexOf(tm.industryClassId.toString())>=0 && (tm.id==8 || tm.id==21)
                // })
                t.industryChainList=resp2.data.dataList.filter(tm=>{
                  return childClassAry.indexOf(tm.industryClassId.toString())>=0 
                })
                if(t.industryChainList.length>0){
                  industryClassList.value.push({
                    id:t.value,
                    name:t.label,
                    industryChainList:t.industryChainList
                  });
                }
              })
              console.log(industryClassList.value)
          }
          else{
              industryClassList.value=[];
          }
      }));
    }
    */
    //选择产业链
    const selIndustryChain=(id)=>{
     router.push(`/industryChain/info?id=${id}`)
    }
    //加载
    getIndustryChainListFun('');//所有产业链
    getIndustryClassListFun();//产业分类
    //记录用户操作日志
    addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载','');
    return{
      windowHeight,
      industryClassList,
      industryChainList,
      selIndustryClass,
      selIndustryClass_name,
      selSubIndustryClass,
      selSubIndustryClass_name,
      searchStr,
      getIndustryChainListFun,
      selIndustryChain
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.icon-arrow{
  display: inline-block;
  color: #99a2aa;
  vertical-align: middle;
  text-align: center;
  font-family: Arial;
  position: absolute;
  right: 10px;
  top: 12px;
}
.grid-content {
  border-radius: 2px;
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  cursor: pointer;
  h5{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .grid-title{
    text-align: right;
    margin-top:20px;
    border-top: 1px solid $bordercolor;
  }
  
  .txt{
    height: 40px;
    display: inline-block;
    line-height: 40px;
  }
  .num{
    color:$specialtext-color;
  }
}
::v-deep .grid-content .el-image{
  height: 260px;
  width: 100%;
}
::v-deep .grid-content .el-image__inner{
 transition: all 0.6s;
 border-top-left-radius: 4px;
 border-top-right-radius: 4px;
}
::v-deep .grid-content:hover .el-image__inner{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
   
}
::v-deep .el-input__inner{
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
::v-deep .el-input-group__append{
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;

}
</style>